<template>
    <div class="sld_chat_page">

        <chatLeftList @curChatMemInfo="curChatMemInfo" @switchMember="switchMember" ref="left"
            :connectBaseData="connectBaseData" />

        <div class="chat_zone" :style="{ 'background': curChatMemberId ? '#fff' : '#f1f1f2' }">
            <section v-show="curChatMemberId">
                <div class="top">
                    <p class="top_title">{{ curChatMemberInfo.data.memberName }}&nbsp;&nbsp;{{ chatSourceUrl }}</p>
                    <div v-if="repairInfo" class="repairBox">
                        <div>维修服务沟通中</div>
                        <!-- 商品信息 -->
                        <div class="goods" style="margin-top: 20rpx;">
                            <div class="avatar">
                                <img :src="repairInfo.mainImage" />
                            </div>
                            <div class="content">
                                <div class="name">{{ repairInfo.goodsName }}</div>
                                <div class="remark">{{ repairInfo.goodsBrief }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="chat_wrap">
                    <!-- 聊天界面框start -->
                    <div class="chat_window" :style="'height:' + (clientHeight - 339) + 'px'">
                        <el-scrollbar ref="scroll" id="chat">
                            <!-- <div v-if="msgList.list.length>0"> -->
                            <div v-for="(item, index) in msgList.list" :key="index">
                                <div v-if="index == 0 || (index > 0 && $isShowTime(msgList.list[index - 1].addTime, item.addTime))"
                                    class="time_line">
                                    {{ $formatChatTime(item.addTime) }}
                                </div>
                                <!-- 会员发送的信息start -->
                                <template v-if="item.userType == 1">

                                    <!-- 文本类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 1">
                                        <img :src="item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p>{{ item.memberName }}</p>
                                            <span class="content_text text_type"
                                                v-html="JSON.parse(item.msgContent).content"></span>
                                        </div>
                                    </div>
                                    <!-- 文本类型end -->

                                    <!-- 图片类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 2">
                                        <img :src="item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text image_type">
                                                <el-image :src="JSON.parse(item.msgContent).pic"
                                                    :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                                    hide-on-click-modal="true">
                                                    <template #placeholder>
                                                        <div class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </template>
                                                </el-image>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 图片类型end -->

                                    <!-- 商品类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 3">
                                        <img :src="item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text goods_type">
                                                <div class="goods_model" :key="index">
                                                    <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom">
                                                            <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 商品类型end -->

                                    <!-- 订单类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 4">
                                        <img :src="item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text order_type">
                                                <div class="order_title">
                                                    <span class="order_sn">{{ L['订单号'] }}：<i>{{
                                                        JSON.parse(item.msgContent).orderSn }}</i></span>
                                                    <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                    }}</span>
                                                </div>
                                                <div class="goods_model order_type">
                                                    <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom goods_info_bottom_detial">
                                                            <span>￥{{ JSON.parse(item.msgContent).productShowPrice ?
                                                                JSON.parse(item.msgContent).productShowPrice :
                                                                JSON.parse(item.msgContent).goodsPrice }}</span>
                                                            <span class="goods_info_bottom_ok">{{
                                                                JSON.parse(item.msgContent).orderStateValue }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 订单类型end -->
                                    <!-- 订单类型start -->
                                    <div class="chat_ladder user" v-if="item.msgType == 6">
                                        <img :src="item.memberAvatar" class="conten_left">
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text order_type">
                                                <div class="order_title">
                                                    <span class="order_sn">【维修订单】{{ L['订单号'] }}：<i>{{
                                                        JSON.parse(item.msgContent).orderSn }}</i></span>
                                                    <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                    }}</span>
                                                </div>
                                                <div class="goods_model order_type">
                                                    <img :src="JSON.parse(item.msgContent).photos.split(',')[0]" alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).title }}
                                                        </div>
                                                        <div>可在商家管理端查看订单详情</div>
                                                        <!-- <div class="goods_info_bottom goods_info_bottom_detial">
                                                            <span>￥{{ JSON.parse(item.msgContent).orderAmount }}</span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 1">
                                                                待确认
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 2">
                                                                已确认
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 3">
                                                                重新报价
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 4">
                                                                已取消
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 5">
                                                                用户寄出
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 6">
                                                                商家收货
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 7">
                                                                商家报价
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 8">
                                                                用户确认最终价格
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 9">
                                                                维修完成
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 10">
                                                                寄回商品
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 11">
                                                                用户确认
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 12">
                                                                用户评价
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 13">
                                                                商家上门维修
                                                            </span>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 订单类型end -->
                                </template>
                                <!-- 会员发送的信息end -->


                                <!-- 客服发送的消息start -->
                                <template v-if="item.userType == 2">
                                    <!-- 文本类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 1">
                                        <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right">
                                            <p class="name">{{ item.vendorName }}</p>
                                            <span class="content_text text_type"
                                                v-html="JSON.parse(item.msgContent).content"></span>
                                        </div>
                                        <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                    </div>
                                    <!-- 文本类型end -->

                                    <!-- 商品类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 3">
                                        <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right">
                                            <p class="name">{{ item.vendorName }}</p>
                                            <div class="content_text goods_type">
                                                <div class="goods_model" :key="index">
                                                    <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom">
                                                            <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                    </div>
                                    <!-- 商品类型end -->


                                    <!-- 订单类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 4">
                                        <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right">
                                            <p class="name">{{ item.vendorName }}</p>
                                            <div class="content_text order_type">
                                                <div class="order_title">
                                                    <span class="order_sn">{{ L['订单号'] }}：<i>{{
                                                        JSON.parse(item.msgContent).orderSn }}</i></span>
                                                    <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                    }}</span>
                                                </div>
                                                <div class="goods_model order_type">
                                                    <img :src="JSON.parse(item.msgContent).goodsImage" alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).goodsName }}
                                                        </div>
                                                        <div class="goods_info_bottom goods_info_bottom_detial">
                                                            <span>￥{{ JSON.parse(item.msgContent).goodsPrice }}</span>
                                                            <span class="goods_info_bottom_ok">{{
                                                                JSON.parse(item.msgContent).orderStateValue }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                    </div>
                                    <!-- 订单类型end -->

                                    <!-- 图片类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 2">
                                        <div :class="{ msg_read: item.msgState == 1, msg_not_read: item.msgState == 2 }">
                                            {{ item.msgState == 1 ? L['已读'] : L['未读'] }}</div>
                                        <div class="content_right">
                                            <p class="name">{{ item.vendorName }}</p>
                                            <div class="content_text image_type">
                                                <el-image :src="JSON.parse(item.msgContent).pic"
                                                    :preview-src-list="[JSON.parse(item.msgContent).pic]"
                                                    hide-on-click-modal="true">
                                                    <template #placeholder>
                                                        <div class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </template>
                                                </el-image>
                                            </div>
                                        </div>
                                        <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                    </div>
                                    <!-- 图片类型end -->
                                    <!-- 订单类型start -->
                                    <div class="chat_ladder merchant" v-if="item.msgType == 6">
                                        <!-- <img :src="item.memberAvatar" class="conten_left"> -->
                                        <div class="content_right">
                                            <p class="name">{{ item.memberName }}</p>
                                            <div class="content_text order_type">
                                                <div class="order_title">
                                                    <span class="order_sn">【维修订单】{{ L['订单号'] }}：<i>{{
                                                        JSON.parse(item.msgContent).orderSn }}</i></span>
                                                    <span class="order_time">{{ JSON.parse(item.msgContent).createTime
                                                    }}</span>
                                                </div>
                                                <div class="goods_model order_type">
                                                    <img :src="JSON.parse(item.msgContent).photos.split(',')[0]" alt="">
                                                    <div class="goods_info">
                                                        <div class="goods_info_title">
                                                            {{ JSON.parse(item.msgContent).title }}
                                                        </div>
                                                        <div>可在商家管理端查看订单详情</div>
                                                        <!-- <div class="goods_info_bottom goods_info_bottom_detial">
                                                            <span>￥{{ JSON.parse(item.msgContent).orderAmount }}</span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 1">
                                                                待确认
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 2">
                                                                已确认
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 3">
                                                                重新报价
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 4">
                                                                已取消
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 5">
                                                                用户寄出
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 6">
                                                                商家收货
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 7">
                                                                商家报价
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 8">
                                                                用户确认最终价格
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 9">
                                                                维修完成
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 10">
                                                                寄回商品
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 11">
                                                                用户确认
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 12">
                                                                用户评价
                                                            </span>
                                                            <span class="goods_info_bottom_ok"
                                                                v-if="JSON.parse(item.msgContent).orderState == 13">
                                                                商家上门维修
                                                            </span>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <img :src="item.vendorAvatar" alt="" class="user_avatar">
                                    </div>
                                    <!-- 订单类型end -->
                                </template>
                                <!-- 客服发送的消息end -->
                            </div>
                            <!-- </div> -->
                            <!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
                            <div></div>
                        </el-scrollbar>
                    </div>
                    <!-- 聊天界面框end -->

                    <!-- 聊天输入框start -->
                    <div class="chat_input" id="chat_input">
                        <div class="chat_tool">
                            <!-- 表情start -->
                            <el-popover placement="top-start" :width="300" trigger="click">
                                <template #reference>
                                    <a class="face_ex">
                                        <img src="@/assets/service/face_ex.png" alt="">
                                        <!-- <i class="iconfont icon-biaoqing"></i> -->
                                    </a>
                                </template>
                                <div class="emoji_s">
                                    <div v-for="(e, i) in emojis" :key="i" :title="e.title" class="emoji_item"
                                        @click="transEmoji(e.src)">
                                        <img :src="require('@/assets/emoji/' + (e.src))" alt="">
                                    </div>
                                </div>
                            </el-popover>
                            <!-- 表情end -->

                            <!-- 图片start -->
                            <label for="image">
                                <img src="@/assets/service/pic.png" alt="">
                            </label>
                            <input type="file" id="image" @change="getImage">
                            <!-- 图片end -->

                            <!-- 快捷语恢复start -->
                            <el-popover placement="top-start" :width="780" trigger="click" v-model:visible="popFlag">
                                <template #reference>
                                    <img src="@/assets/service/quick_reply.png" alt="" @click="getQuickReplyActive">
                                </template>
                                <div class="pop_header">
                                    <p>{{ L['常用语快捷回复'] }}</p>
                                    <p><i class="el-icon-close" @click="popFlag = false"></i></p>
                                </div>
                                <div class="pop_list"
                                    v-if="quickReplyList && quickReplyList.list && quickReplyList.list.length > 0">
                                    <el-scrollbar>
                                        <div class="pop_item" v-for="(item, index) in quickReplyList.list" :key="index"
                                            @click="sendQuick(item.msgContent)">
                                            {{ item.msgContent }}
                                        </div>
                                    </el-scrollbar>
                                </div>
                                <div class="empty_quick" v-if="!quickReplyList.list.length > 0">
                                    <img src="@/assets/goods/empty_data.png" alt="">
                                    <p>{{ L['暂未设置快捷回复~'] }}</p>
                                </div>
                            </el-popover>
                            <!-- 快捷语end -->

                            <!-- 转接start -->
                            <img src="@/assets/service/tran_touch.png" alt="" @click="chatTransfer">
                            <!-- 转接end -->

                            <!-- 服务订单start -->
                            <img src="@/assets/service/repair.png" v-if="repairInfo" alt="" @click="openRepairDialog">
                            <!-- 服务订单end -->
                        </div>
                        <div class="chat_input_area">
                            <!-- <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea" resize="none"
                                id="el-textarea" @keyup.enter="send()">
                            </el-input> -->
                            <pre name="sendBox" contenteditable="true" class="send-textarea" @input="preInput"
                                ref="sendBox"></pre>
                        </div>
                        <div class="input_button">
                            <button @click="send()">{{ L['发送'] }}<i class="iconfont"></i></button>
                        </div>
                    </div>
                    <!-- 聊天输入框end -->
                </div>

                <!-- 客服转接框start -->
                <el-dialog title="客服列表" v-model="dialogVisible" width="20%" :before-close="handleClose" top="25vh">
                    <el-scrollbar>
                        <div class="transfer_list">
                            <div class="transfer_item" v-for="(venItem, venIndex) in vendorList.list" :key="venIndex">
                                <img :src="storeInfo.data.storeLogoUrl" alt="">
                                <div class="text_con">
                                    <p class="service_name">{{ venItem.vendorName }}</p>
                                    <p class="service_state">{{ L['在线'] }}</p>
                                </div>
                                <img src="@/assets/service/contact_on.png" alt="" @click="vendorTrans(venItem.vendorId)">
                            </div>
                            <div class="empty_trans" v-show="!vendorList.list.length > 0">
                                <img src="@/assets/goods/empty_data.png" alt="">
                                <p>{{ L['暂无在线客服~'] }}</p>
                            </div>
                        </div>
                    </el-scrollbar>
                </el-dialog>
                <!-- 客服转接框end -->

            </section>
            <section v-show="!curChatMemberId">
                <div class="empty_data">
                    <img src="@/assets/goods/empty_data.png" />
                    <p>{{ L['请选择访客开启对话～'] }}</p>
                </div>
            </section>
        </div>

        <chatRightList @getObj="getObj" ref="right" />

        <!-- 创建服务单框start -->
        <el-dialog title="发起维修订单" v-model="repairVisible" width="30%" :before-close="handleCloseRepair" top="25vh">
            <el-form ref="ruleFormRef" :model="repairForm" label-width="120px" :rules="repairFormRules">
                <el-form-item label="问题标题" prop="title">
                    <el-input v-model="repairForm.title" placeholder="请输入问题标题" />
                </el-form-item>
                <el-form-item label="问题描述" prop="describe">
                    <el-input v-model="repairForm.describe" placeholder="请输入问题描述" type="textarea" />
                </el-form-item>
                <el-form-item label="问题图片" prop="photos">
                    <!-- <el-input v-model="repairForm.photos" placeholder="请输入问题图片"/> -->
                    <el-upload v-model:file-list="repairForm.fileList" :action="uploadUrl" :headers="header"
                        list-type="picture-card" :on-preview="handlePictureCardPreview" :on-remove="handleRemove"
                        :data="{ source: 'goods' }" :on-success="handleSuccessImg">
                        <img src="@/assets/service/pic.png" alt="">
                    </el-upload>
                </el-form-item>
                <el-form-item label="预估金额" prop="orderAmount">
                    <el-input-number v-model="repairForm.orderAmount" placeholder="请输入预估金额" />
                </el-form-item>
                <el-form-item label="寄修收货人" prop="receiverNameSeller">
                    <el-input v-model="repairForm.receiverNameSeller" placeholder="请输入寄修收货人" />
                </el-form-item>
                <!-- <el-form-item label="寄修地址" prop="receiverAddressSeller"> -->
                <!-- <template v-for="item of pcaTextArr">{{ item.label }}</template> -->
                <!-- <Cascader :options="options.list" v-model="repairForm.receiverAreaInfoSeller"></Cascader> -->
                <!-- <el-cascader v-model="repairForm.receiverAreaInfoSeller" :options="options.list"  @change="handleChange" /> -->
                <!-- </el-form-item> -->
                <el-form-item label="寄修地址" prop="receiverAddressSeller">
                    <el-input v-model="repairForm.receiverAddressSeller" placeholder="请输入寄修地址" />
                </el-form-item>
                <el-form-item label="寄修手机" prop="receiverMobileSeller">
                    <el-input v-model="repairForm.receiverMobileSeller" placeholder="请输入寄修手机" />
                </el-form-item>
                <el-form-item>
                    <el-button @click="repairVisible = false">取消</el-button>
                    <el-button type="primary" @click="handleOkForm(ruleFormRef)">
                        确认
                    </el-button>
                </el-form-item>
            </el-form>
            <!-- <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="repairVisible = false">取消</el-button>
                            <el-button type="primary" @click="handleOkForm(ruleFormRef)">
                                确认
                            </el-button>
                        </span>
                    </template> -->
        </el-dialog>
        <!-- 创建服务单框end -->
    </div>
</template>
<script>
import "element-plus/lib/index.full.js"
import { ref, reactive, getCurrentInstance, onMounted, watch, watchEffect } from 'vue'
import chatLeftList from './chatLeftList';
import chatRightList from './chatRightList';
import { useStore } from "vuex";
import { ElMessage, ElImage } from 'element-plus'
// import { Cascader  } from 'ant-design-vue';
import { emoji, emojiPath } from '@/utils/data.js'; import qs from "qs";
import { getLocalStorageStr } from '../../../utils/common'
import { apiUrl } from '../../../utils/config'
import {
    pcaTextArr
} from "element-china-area-data";
export default {
    name: 'chatPage',
    components: {
        chatLeftList,
        chatRightList,
        ElImage
    },
    beforeCreate() {
        this.$socket.emit("connect_success", this.connectBaseData);
        //监听连接成功事件
        this.sockets.subscribe('get_room_info', (data) => {
            this.socketInfo.data = data;
        });
        this.sockets.subscribe('get_send_msg', (data) => {
            //如果是会员发送过来的话需要播放声音
            if (data.userType == 1 && (localStorage.msgVoiceSetting === true || localStorage.msgVoiceSetting === 'true')) {
                this.play();
            }
            this.msgList.list.push(data);
        });
        this.sockets.subscribe('get_member_source_url', (data) => {
            this.chatSourceUrl = decodeURIComponent(data.sourceUrl);
        });
        this.sockets.subscribe('get_read_msg', (data) => {
            let allData = data.msgIds.split(',');
            this.msgList.list.map(item => {
                if (allData.indexOf(item.msgId)) {
                    item.msgState = 1;
                }
            });
        });
        this.sockets.subscribe('get_member_read_all', () => {
            this.msgList.list.map(item => {
                item.msgState = 1;
            });
        });

    },
    setup() {
        const connectBaseData = { storeId: localStorage.storeId, userId: localStorage.vendorId, role: 2 };
        // const connectBaseData = { storeId: 4, userId: 4, role: 2 };
        const { proxy } = getCurrentInstance();
        const store = useStore();
        const quickReplyList = reactive({
            list: []
        })
        const L = proxy.$getCurLanguage()

        const storeInfo = reactive({ data: store.state.storeInfo });
        const msgVoiceSetting = ref(store.state.msgVoiceSetting);
        const textarea = ref('');
        const chatSourceUrl = ref('');//当前会员的页面来源
        const curChatMemberId = ref('');//当前聊天窗口会员Id
        const curChatMemberInfo = reactive({ data: {} });//当前聊天会员的信息
        const socketInfo = reactive({ data: {} });//socket连接成功返回的房间信息
        const msgList = reactive({ list: [] });//聊天列表
        const options = reactive({ list: pcaTextArr });//聊天列表
        const dialogVisible = ref(false);
        const repairVisible = ref(false);
        const popFlag = ref(false)
        const hasMore = ref(true);
        const minMsgId = ref('');//当前消息聊天记录列表里的最小消息id
        const vendorList = reactive({
            list: []
        })
        const scrollTop = ref(false)
        const flag = ref('')
        const clientHeight = ref(0)
        const emojis = reactive(emoji)
        const repairInfo = ref()
        // const ruleFormRef = ref(null)
        const repairForm = reactive({
            title: '',
            describe: '',
            photos: '',
            photoList: [],
            orderAmount: '',
            receiverNameSeller: '',
            receiverAreaInfoSeller: '',
            receiverAddressSeller: '',
            receiverMobileSeller: '',
            fileList: []
        })
        const repairFormRules = reactive({
            title: [{ required: true, message: '请填写问题标题', trigger: 'change' }],
            describe: [{ required: true, message: '请填写问题描述', trigger: 'change' }],
            photos: [{ required: true, message: '请上传问题图片', trigger: 'change' }],
            orderAmount: [{ required: true, message: '请填写预估价格', trigger: 'blur' }],
            receiverNameSeller: [{ required: true, message: '请填写寄修收货人', trigger: 'change' }],
            receiverAddressSeller: [{ required: true, message: '请填写寄修收货地址', trigger: 'change' }],
            receiverMobileSeller: [{ required: true, message: '请填写寄修收货手机', trigger: 'change' }]
        })
        /**
         * 初始化请求头和数据
         * @zjf-2020-12-25
         */
        const initRequestHeader = async (method, url, data = {}, type = 'urlencoded') => {
            let result = {};
            let headers = {};

            //用户登录状态下，每次更新refresh_token58分钟之后需要更新access_token
            if (getLocalStorageStr('access_token')) {
                let cur_time = new Date().getTime();
                if (cur_time - getLocalStorageStr('time') * 1 > 58 * 60 * 1000) {
                    let param = new FormData();
                    param.append('grant_type', 'refresh_token');
                    param.append('refresh_token', getLocalStorageStr('sld_refresh_token'));
                    // param.append('refresh_token', 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo1LCJ1c2VyX25hbWUiOiJzbG9kb24wMyIsInNjb3BlIjpbImFsbCJdLCJ2ZW5kb3Jfcm9sZV9pZCI6MiwiYXRpIjoiYjA4NDYzZjItZTZlMC00YWI0LTk2OGQtOWVmOTdmZWI2ZDFmIiwiZXhwIjoxNjE3NzgyOTQwLCJ1dWlkIjoiMDI0OGQyZDgtZmRlNC00ZDJjLWIxZmYtNDU4ZTEzYzhkZTQ1IiwianRpIjoiM2E4ZGVlMjAtYzI5YS00YzI0LThjMWUtY2UxNDc2YjQwMTMzIiwid2ViSWRlbnRpZnkiOiJzZWxsZXIiLCJjbGllbnRfaWQiOiJzZWxsZXIifQ.AIJ-piFyT0iTJns9XFg0vs6mXAC0cxvN_WNU2Pk3qWR_VTQ1At0BNAzizu3vFbURPGDg8Pi-nWQG5RDaAnuwio-uTT1dIvRayBiw37HDkUuqrN-gYk915OVvb0GYEHSM_szt2DM_FrRUv54CV0vOhqdO57Y03DKrqPciPvJyaSU')
                    await proxy.$post('/v3/sellerLogin/oauth/token', param, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Basic VVcxS2FsRlhVblJoVnpROTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09'
                        }
                    }).then((response) => {
                        if (response.data.state == 200) {
                            localStorage.setItem('sld_token', response.data.data.access_token);
                            localStorage.setItem('sld_refresh_token', response.data.data.refresh_token);
                            localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                        }
                    }, err => {
                        console.log('更新access_token出错：', err);
                    })
                }
            }

            if (method == 'post') {
                if (type == 'urlencoded') {
                    headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
                    data = qs.stringify(data);
                } else if (type == 'json') {
                    headers = { 'Content-Type': 'application/json' };
                    data = JSON.parse(JSON.stringify(data));
                } else if (type == 'form') {//form-data
                    headers = { 'Content-Type': 'multipart/form-data' };
                    let tmp_data = new FormData();
                    Object.keys(data).forEach((item) => {
                        tmp_data.append(item, data[item])
                    });
                    data = tmp_data;
                }
            }

            if (url.indexOf('/v3/sellerLogin/oauth/token') > -1) {
                headers.Authorization = 'Basic VVcxS2FsRlhVblJoVnpROTpVMjFHTWxsVlFrUmlNMEkxVlcxc2JtRklVa0ZWTW5oMldrYzVkUT09';
            } else {
                console.info('token_value', getLocalStorageStr('sld_token'));
                let token = getLocalStorageStr('sld_token');
                // let token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjo0LCJ1c2VyX25hbWUiOiJzbG9kb24wMiIsInNjb3BlIjpbImFsbCJdLCJ2ZW5kb3Jfcm9sZV9pZCI6MiwiZXhwIjoxNjMyNzQ3NzYwLCJ1dWlkIjoiOWMwODE1ZWUtNDY1MS00ZjdhLWFmODctNDk2MzgwNWFmN2RiIiwianRpIjoiZWE0MWI3YTgtZGZhMi00MzYxLTgwNTQtNmMzMWIxNjcxODUzIiwid2ViSWRlbnRpZnkiOiJzZWxsZXIiLCJjbGllbnRfaWQiOiJzZWxsZXIifQ.HwJ7nHWm3RY6abU3QPMYXisIe6JH0W_vftP4z2j1iRo2NrsqLeLp4Yich0EnwOKkjWruP0lZwnCVtie_N0oC-cPSALycHAsJLTLPA3e7IzuEWmEJWlamFc9rMwGPQk0X1WvgRHajERGivJt5Ki7iC4rv4239Ldaidkh5Tp3WH-Q'
                headers.Authorization = 'Bearer ' + token;

            }

            result = { url, data, headers };
            return result;
        }
        const uploadUrl = apiUrl + 'v3/oss/common/upload'
        let reqHeader = initRequestHeader('post', uploadUrl, { source: 'goods' }, 'from');
        const header = reqHeader.headers
        //发送消息
        const send = () => {
            let content = textarea.value; //没有内容的话直接结束
            if (!content.trim() && !content) {
                return false;
            }
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.vendorId = socketInfo.data.vendorId;
            msgData.msgType = '1';
            msgData.msg = {
                content: content
            };
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            textarea.value = '';//清空输入框的内容
            proxy.$refs.sendBox.innerHTML = ''
            scrollTop.value = false

            scrolltoNewMsg()
        }

        //打开客服转接
        const chatTransfer = () => {
            dialogVisible.value = true
            proxy.$socket.emit('pre_switch_vendor', { ...connectBaseData }, (data) => {
                vendorList.list = data
            })
        }

        // 点击转接客服
        const vendorTrans = (vendorId) => {
            proxy.$socket.emit('switch_vendor', { switchVendorId: vendorId, memberId: curChatMemberId.value, ...connectBaseData }, (res) => {
                if (res.state == 200) {
                    //删除将该会员从最近联系人列表中删除
                    proxy.$refs.left.closeChatMember(curChatMemberId.value)
                    ElMessage({
                        showClose: true,
                        message: res.msg,
                        type: 'success'
                    });
                    dialogVisible.value = false;
                }
            })
        }

        //获取现在的会员的信息
        const curChatMemInfo = (data) => {
            if (data.memberId != undefined && data.memberId) {
                curChatMemberInfo.data = data;
            } else {
                curChatMemberId.value = 0;
            }
        }

        //切换会员需要重新获取聊天列表以及用户的信息
        const switchMember = (data) => {
            let { memberId, memberName } = data;
            curChatMemberInfo.data.memberName = memberName;
            curChatMemberId.value = memberId;
            scrollTop.value = false
            hasMore.value = true
            flag.value = memberId.toString()
            minMsgId.value = '';
            msgList.list = [];

            getChatLog();
            proxy.$refs.right.getSwitch(curChatMemberId.value)

        }

        //获取订单，足迹，推荐商品，并发送
        const getObj = (data, type) => {
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.vendorId = socketInfo.data.vendorId;
            if (type == 'foot') {
                msgData.msgType = 3;
                msgData.msg = {
                    productId: data.productId,
                    goodsImage: data.goodsImage,
                    goodsName: data.goodsName,
                    goodsPrice: data.productPrice,
                };
            } else if (type == 'recom') {
                msgData.msgType = 3;
                msgData.msg = {
                    productId: data.productId,
                    goodsImage: data.mainImage,
                    goodsName: data.goodsName,
                    goodsPrice: data.goodsPrice,
                };
            } else if (type === 'order') {
                msgData.msgType = 4;
                msgData.msg = {
                    orderSn: data.orderSn,
                    createTime: data.createTime,
                    orderStateValue: data.orderStateValue,
                    productId: data.goodsItem.productId,
                    goodsImage: data.goodsItem.productImage,
                    goodsName: data.goodsItem.goodsName,
                    goodsPrice: data.goodsItem.productShowPrice,
                };
            }
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            scrollTop.value = false
            scrolltoNewMsg()
        }

        //获取快捷回复列表
        const getQuickReplyActive = () => {
            popFlag.value = true
            let params = {
                current: 1
            }
            proxy.$get('v3/helpdesk/seller/quick/list', params).then(res => {
                if (res.state == 200) {
                    quickReplyList.list = res.data.list.filter(item => item.isShow == 1)
                }

            })
        }

        //获取聊天记录
        const getChatLog = () => {
            let params = {
                memberId: curChatMemberId.value,
            }
            if (minMsgId.value) {
                params.msgId = minMsgId.value;
            }
            proxy.$post('v3/helpdesk/seller/chat/msgLog', params).then(res => {
                if (res.state == 200) {
                    if (minMsgId.value) {
                        msgList.list = Array.from(res.data).concat(msgList.list);
                    } else {
                        msgList.list = res.data
                    }
                    if (res.data.length > 0) {
                        changeMsgState(res.data);
                    }
                    if (msgList.list.length > 0) {
                        minMsgId.value = msgList.list[0].msgId;
                    }
                    hasMore.value = res.data.length < 10 ? false : true;
                }
            })

            console.log('何时进行 getChatLog')
            getRepairInfo();
        }
        /**获取服务信息 */
        const getRepairInfo = () => {
            console.log('那里有memberId：', curChatMemberId.value)
            if (curChatMemberId.value) {
                proxy.$get('v3/msg/seller/msg/repairDetail?memberId=' + curChatMemberId.value).then(res => {
                    console.log('v3/msg/seller/msg/repairDetail', res)
                    repairInfo.value = res.data

                })
                // repairInfo.value = {
                //     actualSales: 0,
                //     address: null,
                //     afterSaleService: 0,
                //     barCode: null,
                //     brandId: null,
                //     brandName: null,
                //     categoryId1: null,
                //     categoryId2: null,
                //     categoryId3: null,
                //     categoryPath: null,
                //     commentGrade: null,
                //     commentNumber: 0,
                //     createTime: "2023-11-14 16:16:52",
                //     defaultProductId: null,
                //     goodsBrief: "sss",
                //     goodsId: 1724340605473132500,
                //     goodsIdStr: "1724340605473132544",
                //     goodsName: "标致111",
                //     goodsPrice: null,
                //     goodsStock: null,
                //     goodsType: 1,
                //     goodsVideo: null,
                //     id: 3,
                //     isDelete: 0,
                //     isLock: 0,
                //     isOffline: 0,
                //     isRecommend: 0,
                //     isSelf: null,
                //     isSpec: 0,
                //     isVatInvoice: null,
                //     isVirtualGoods: 1,
                //     keyword: null,
                //     latitude: null,
                //     longitude: null,
                //     mainImage: "https://ti-daoshop.oss-cn-beijing.aliyuncs.com/images/seller/goods/LU1v5OYXz6MXTBKcsrN.jpg",
                //     mainSpecId: 0,
                //     marketPrice: null,
                //     onlineTime: "2023-11-14 16:16:52",
                //     platformGoodsId: null,
                //     promotionPrice: null,
                //     promotionType: null,
                //     serviceLabelIds: null,
                //     state: 3,
                //     storeId: 360003,
                //     storeIsRecommend: null,
                //     storeName: null,
                //     storeState: 1,
                //     updateTime: "2023-11-17 08:39:37",
                //     virtualSales: 12233,
                // }

            }
        }
        /***打开弹窗 */
        const openRepairDialog = () => {
            repairVisible.value = true
        }
        /***关闭弹窗 */
        const handleCloseRepair = () => {
            repairVisible.value = false
        }
        const transEmoji = (src) => {
            const imgSrc = (emojiPath) + "" + (src);
            const imgTag = document.createElement("img");
            imgTag.src = imgSrc;
            proxy.$refs.sendBox.appendChild(imgTag);
            textarea.value = proxy.$refs.sendBox.innerHTML
        }


        const preInput = (e) => {
            textarea.value = e.target.innerHTML
        }

        //修改当前消息列表的未读消息为已读
        const changeMsgState = (data) => {
            let tmpMsgIdArray = [];
            data.map(item => {
                if (item.userType == 1 && item.msgState == 2) {
                    tmpMsgIdArray.push(item.msgId);
                }
            });
            if (tmpMsgIdArray.length > 0) {
                proxy.$socket.emit("read_msg", {
                    msgIds: tmpMsgIdArray.join(','),
                    memberId: curChatMemberId.value,
                    ...connectBaseData
                });
            }
        }




        //切换店铺的滑动操作，而且触顶加载则保持滚动条在触顶时的位置，不大跳
        watch(() => flag.value, (nv, ov) => {
            let el = ''
            let client = ''
            if (nv != ov) {
                el = document.getElementById('chat').childNodes[0]
                client = document.getElementById('chat')
                scrollTop.value = false
                new MutationObserver(() => {
                    if (scrollTop.value) {
                        el.scrollTop = client.clientHeight + 400
                        return
                    }
                    scrollToBottom(el);
                }).observe(el, { childList: true, subtree: true });

            }
        })

        watchEffect(() => {
        })


        //滑动至底部方法
        const scrollToBottom = el => {
            el.scrollTop = el.scrollHeight;
            el.scrollTo(0, el.scrollHeight)
        };

        //MutationObserver监听dom变化，当聊天数据最终渲染完成时，将其滑动至底部(优化方法)
        const scroll = () => {
            let el = proxy.$refs.scroll.wrap
            let timeout = ''
            el.addEventListener('scroll', () => {
                if (timeout) clearTimeout(timeout);
                timeout = window.setTimeout(function () {
                    if (el.scrollTop == 0 && hasMore.value) {

                        scrollTop.value = true
                        getChatLog()
                    }
                }, 500);
            }, true);
            (new MutationObserver(() => {
                if (scrollTop.value) {
                    el.scrollTop = el.clientHeight + 400
                    return
                }
                scrollToBottom(el);
            })).observe(el, { childList: true, subtree: true });
        }

        //发送快捷回复
        const sendQuick = (msg) => {
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.vendorId = socketInfo.data.vendorId;
            msgData.msgType = '1';
            msgData.msg = {
                content: msg
            };

            popFlag.value = false
            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
            scrollTop.value = false
            scrolltoNewMsg()
        }

        //发送消息时并，滚到最底部
        const scrolltoNewMsg = () => {
            var div = proxy.$refs.scroll.wrap
            proxy.$nextTick(() => {
                div.scrollTo(0, div.scrollHeight + 10000)
            })

        }

        // 消息提示音
        const play = () => {
            let audioElement = document.createElement('audio');
            let voice = require('@/assets/media/msg.mp3')
            audioElement.setAttribute('src', voice);
            audioElement.setAttribute('autoplay', 'autoplay');
        }

        // 发送图片
        const getImage = (e) => {
            if (e.target.files[0]) {
                proxy.$post('/v3/oss/common/upload', {
                    source: 'goods',
                    file: e.target.files[0]
                }, 'form').then(res => {
                    if (res.state == 200) {
                        let msgData = {}
                        msgData.memberId = socketInfo.data.memberId;
                        msgData.vendorId = socketInfo.data.vendorId;
                        msgData.msgType = '2';
                        msgData.msg = {
                            pic: res.data.url,
                            width: res.data.width,
                            height: res.data.height
                        };
                        proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
                        proxy.$nextTick(() => {
                            setTimeout(() => {
                                scrollTop.value = false
                                scrolltoNewMsg()
                            }, 10)

                        })

                    }

                })
            }
        }

        const handleSuccessImg = (e) => {
            console.log('handleSuccessImg', e)
            if (e.data.url) {
                repairForm.photoList.push(e.data.url)
            }
            console.log('repairForm.photoList', repairForm.photoList)

        }

        const handlePictureCardPreview = (e) => {
            console.log('handlePictureCardPreview', e)
        }
        const handleRemove = (e) => {
            console.log('handleRemove', e.response.data)
            if (e.response) {
                repairForm.photoList = repairForm.photoList.filter(item => item != e.response.data.url)
            }
        }
        const handleOkForm = () => {
            console.log('handleOkForm formRef')
            repairForm.photos = repairForm.photoList + ''
            proxy.$refs.ruleFormRef.validate((valid, fields) => {
                if (valid) {
                    // console.log('submit!000', socketInfo.data, repairInfo.value)
                    repairForm.memberId = socketInfo.data.memberId;
                    // console.log('submit!101',repairInfo.value.goodsId)
                    repairForm.repairId = repairInfo.value.id;
                    repairForm.receiverAreaInfoSeller = repairForm.receiverAddressSeller
                    console.log('submit!111', repairForm)
                    proxy.$post('/v3/diagnosis/seller/repairOrder/add', repairForm).then(res => {
                        if (res.state == 200) {
                            console.log('res.state', res)
                            repairVisible.value = false
                            let msgData = {}
                            console.log('res.state', 1)
                            msgData.memberId = socketInfo.data.memberId;
                            console.log('res.state', 2)
                            msgData.vendorId = socketInfo.data.vendorId;
                            console.log('res.state', 3)
                            msgData.msgType = '6';
                            console.log('res.state', res)
                            msgData.msg = {
                                content: res.data,
                                ...res.data
                            };
                            // msgData.msg.content = res.data;
                            console.log('send_msg msgData!111', msgData)
                            console.log('send_msg connectBaseData!111', connectBaseData)
                            proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
                            proxy.$nextTick(() => {
                                setTimeout(() => {
                                    scrollTop.value = false
                                    scrolltoNewMsg()
                                }, 10)

                            })
                        } else {
                            // console.log('/v3/diagnosis/seller/repairOrder/add', res)

                            ElMessage({
                                showClose: true,
                                message: res.msg,
                                type: 'error'
                            });
                        }
                    })
                } else {
                    console.log('error submit!', fields)
                }
            })
        }
        onMounted(() => {
            clientHeight.value = document.body.clientHeight || document.documentElement.clientHeight
            scroll()
        })

        return {
            textarea,
            msgList,
            socketInfo,
            send,
            chatTransfer,
            dialogVisible,
            getObj,
            getQuickReplyActive,
            quickReplyList,
            sendQuick,
            popFlag,
            getChatLog,
            chatSourceUrl,
            curChatMemInfo,
            curChatMemberInfo,
            switchMember,
            minMsgId,
            changeMsgState,
            curChatMemberId,
            vendorList,
            vendorTrans,
            play,
            msgVoiceSetting,
            getImage,
            connectBaseData,
            storeInfo,
            clientHeight,
            emojis,
            preInput,
            transEmoji,
            emojiPath,
            repairInfo,
            repairVisible,
            handleCloseRepair,
            openRepairDialog,
            repairForm,
            repairFormRules,
            header,
            uploadUrl,
            handleSuccessImg,
            handlePictureCardPreview,
            handleRemove,
            handleOkForm,
            options,
            L
        }
    }
}
</script>
<style lang="scss">
.pop_header {
    height: 38px;
    background: #F3F3F4;
    display: flex;
    justify-content: space-between;

    p {
        margin-left: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 38px;
    }

    i {
        font-size: 16px;
        margin-right: 10px;
    }
}

.emoji_s {
    height: 200px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;

    .emoji_item {
        padding: 0 5px;
    }
}

.send-textarea {
    box-sizing: border-box;
    width: 100%;
    height: 94px;
    padding: 0px 19px 0px 19px;
    background-color: #fff;
    color: #252525;
    font-size: 13px;
    font-family: inherit;
    word-break: break-all;
    white-space: normal;
    overflow-y: auto;
    outline: none;
}

.pop_list {
    margin-top: 5px;
    height: 300px;

    .pop_item {
        margin-left: 10px;
        padding: 12px 0px;
        border-bottom: 1px solid #e7e7e7;
        width: 97%;

        &:hover {
            background: #F8F8F8;
        }
    }
}

.goods_info_bottom_detial {
    position: relative;

    .goods_info_bottom_ok {
        position: absolute;
        padding-left: 5px;
        padding-right: 5px;
        height: 20px;
        background: #EEEEEE;
        border-radius: 10px;
        bottom: 0;
        right: 10px;
        text-align: center;
        line-height: 20px;
        color: #666666 !important;
    }
}

#el-textarea {
    border: none;

    textarea {
        outline: none;
        border: none;
    }
}


.sld_chat_page {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: #fff;
    display: flex;
    // flex-shrink: 0;

    .chat_zone {
        width: 1010px;
        height: 100%;

        .empty_data {
            padding-top: 220px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            img {
                width: 80px;
            }

            p {
                margin-top: 10px;
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #bbb;
            }
        }

        .top {
            // height: 50px;
            padding: 15px 0px;

            .top_title {
                height: 20px;
                line-height: 20px;
                border-left: 4px solid #0563FF;
                padding-left: 10px;
                margin-left: 20px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
        }

        .chat_wrap {
            width: 100%;
            border-radius: 6px;
            /* border-top-right-radius: 6px; */
            border: 1px solid #EEEEEE;

            .chat_window {
                height: 580px;
                background-color: #F5F5F5;
                padding-top: 12px;
                padding-left: 15px;
                padding-right: 13px;

                .time_line {
                    margin: 12px auto;
                    line-height: 22px;
                    text-align: center;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #999999;
                    width: 149px;
                    height: 22px;
                    background: #EEEEEE;
                    border-radius: 11px;
                }

                #text_center {
                    width: 589px;
                    height: 104px;
                    background: #FFFFFF;
                    border-radius: 6px;

                    .goods_model {
                        display: flex;
                        justify-content: center;
                        align-content: center;
                        padding-top: 10px;
                        padding-left: 10px;
                    }
                }

                .chat_ladder {
                    display: flex;
                    align-items: flex-end;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    align-items: flex-start;
                    box-sizing: border-box;

                    &>img {
                        width: 38px;
                        height: 38px;
                        border-radius: 50%;

                        &.user_avatar {
                            margin-left: 10px;
                        }
                    }

                    .content_right {
                        margin-left: 10px;

                        p {

                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #999999;
                            margin-bottom: 5px;
                        }

                        .content_text {
                            display: inline-block;
                            padding: 11px;
                            position: relative;
                            max-width: 589px;
                            background: #FFFFFF;
                            border-radius: 6px;

                        }

                        .order_type {

                            .goods_info {
                                min-width: 300px;

                                .goods_info_title {

                                    font-size: 16px;
                                    font-family: Microsoft YaHei;
                                    font-weight: 400;
                                    color: #333333;
                                }
                            }

                            .order_title {
                                display: flex;
                                height: 20px;
                                justify-content: space-between;
                                align-items: flex-start;
                                border-bottom: 1px solid #F2F2F2;
                                margin-bottom: 8px;

                                .order_sn {
                                    font-size: 12px;
                                    color: #999999;
                                    font-family: Microsoft YaHei;

                                    i {
                                        font-style: normal;
                                        color: #666666;
                                    }
                                }

                                .order_time {
                                    color: #999999;
                                }
                            }
                        }

                        .goods_type {
                            min-width: 300px;
                        }

                        .image_type {

                            min-height: 100px;

                            img {
                                max-width: 200px;
                            }

                            .image-slot {
                                width: 200px;
                                height: 200px;
                            }
                        }

                        .text_type {
                            max-width: 360px;
                            word-break: break-all;
                        }
                    }

                }


                .user {
                    justify-content: flex-start;

                    .content_text {
                        background: #FFFFFF;

                        border-radius: 6px;
                    }
                }

                .merchant {
                    padding-right: 20px;
                    justify-content: flex-end;

                    p {
                        text-align: right;
                    }

                    .content_text {
                        .tiny_triangle {
                            position: absolute;
                            right: -9px;
                            width: 0;
                            height: 0;
                            border-right: 14px solid transparent;
                            border-bottom: 13px solid #fff;
                        }

                        background: #fff;
                        border-radius: 6px;
                    }

                    .msg_read {
                        align-self: flex-end;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #0E6FD7;
                    }

                    .msg_not_read {
                        align-self: flex-end;
                        height: 100%;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #0E6FD7;
                    }
                }
            }

            .chat_input {
                padding-bottom: 10px;

                .chat_tool {
                    padding: 6px;

                    img {
                        margin-right: 10px;
                    }
                }

                .chat_input_area {
                    padding: 6px;
                    margin-top: 6px;

                    textarea {
                        max-height: 94px;

                    }
                }

                button {
                    width: 80px;
                    height: 30px;
                    background: #0E6FD7;
                    border-radius: 6px;
                    border: none;
                    color: #fff;
                }

                .input_button {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 23px;

                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }


            }
        }
    }
}

.goods_model {
    display: flex;

    &>img {
        width: 84px;
        height: 84px;
        background: #707070;
        border-radius: 6px;
    }

    .goods_info {
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 450px;

        .goods_info_title {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            padding-right: 10px;
        }

        .goods_info_bottom {
            display: flex;
            justify-content: flex-start;

            span:first-child {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }

            span:nth-child(2) {
                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #e2231a;
            }
        }
    }
}

.transfer_list {
    padding: 11px 0px 11px 11px;
    height: 317px;

    .transfer_item {
        display: flex;
        border-bottom: 1px solid #F2F2F2;
        padding: 12px 0px;
        position: relative;
        height: 70px;
        width: 98%;

        img {
            width: 40px;
            height: 40px;
        }

        img:nth-of-type(2) {
            position: absolute;
            right: 10px;
            top: 22px;
            width: 23px;
            height: 22px;
        }

        .text_con {
            margin-left: 10px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .service_name {

                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;

            }

            .service_state {

                font-size: 12px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #0E6FD7;
                margin-bottom: 3px;
            }
        }

    }
}

.el-dialog__header {

    background: #F3F3F4;
}

.el-dialog__title {

    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
}

.empty_quick {
    height: 330px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
        width: 87px;
        height: 55px;
    }

    p {
        margin-top: 11px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

.empty_trans {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90%;

    p {
        margin-top: 11px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
    }
}

#image {
    display: none;
}

.repairBox {
    padding: 8px;
    background-color: white;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 380px;

    .goods {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .avatar {
            width: 50px;
            height: 50px;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
            }
        }

        .content {
            width: 295px;
            text-align: left;

            .remark {
                font-size: 14px;
                color: #777777;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
            }

            .price {
                font-size: 20px;
            }
        }
    }
}
</style>
